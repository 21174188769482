$(document).ready(function () {
	$(".carousel-home").owlCarousel({
		loop: true,
		margin: 0,
		nav: true,
		autoplay: true,
		autoplayTimeout: 7000,
		autoplayHoverPause: true,
		items: 1,
	});
	$('#header .header-mobile .botao-menu').click(function () {
		$(this).toggleClass('-aberto')
		$('#header .header-mobile .menu').toggleClass('-aberto');
	});
	$('[data-toggle="tooltip"]').tooltip();

	Polos.buscaEstadoCidade();

	Curso.selectPolos();
	Curso.filtroPresencialEAD();
	Noticias.buscaCategoria();
	Hotsite.Dropdown();

	Site.Cookies();
});


Curso = {};
Curso.selectPolos = function () {
	$selectPolo = $('#curso .inscreva .select-polo');
	$btnInscreva = $('#curso .inscreva .btn-inscreva').parents('a');
	$selectPolo.change(function () {
		if ($(this).val()) {
			$btnInscreva.attr('href', $(this).val());
			$btnInscreva.attr('onclick', "goog_report_conversion ('" + $(this).val() + "')");
			$('#curso .inscreva .btn-inscreva').tooltip('disable');
		} else {
			$btnInscreva.removeAttr('href');
			$('#curso .inscreva .btn-inscreva').tooltip('enable');
		}
	});
}
Curso.filtroPresencialEAD = function () {
	const $inputsType = $('input[name="type"]');


	$inputsType.change(function () {
		$('.cursos .curso').fadeOut(400);
		$inputsType.removeClass('checked');

		if (this.value === 'graduacao-presencial') {
			$('#cursos .banner .titulo .text').html('Graduação');
			$('#cursos .banner .titulo .big').html('Presencial');
			$('.cursos .curso.graduação.presencial').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'graduacao-ead') {
			$('#cursos .banner .titulo .text').html('Graduação');
			$('#cursos .banner .titulo .big').html('EAD');
			$('.cursos .curso.graduação.ead').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'pos-graduacao-presencial') {
			$('#cursos .banner .titulo .text').html('Pós-Graduação');
			$('#cursos .banner .titulo .big').html('Presencial');
			$('.cursos .curso.pós-graduação.presencial').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'pos-graduacao-ead') {
			$('#cursos .banner .titulo .text').html('Pós-Graduação');
			$('#cursos .banner .titulo .big').html('EAD');
			$('.cursos .curso.pós-graduação.ead').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'mestrado-presencial') {
			$('#cursos .banner .titulo .text').html('');
			$('#cursos .banner .titulo .big').html('Mestrado');
			$('.cursos .curso.mestrado.presencial').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'extensao-presencial') {
			$('#cursos .banner .titulo .text').html('Extensão');
			$('#cursos .banner .titulo .big').html('Presencial');
			$('.cursos .extensão.presencial').fadeIn(400);
			$(this).addClass('checked')
		}

		if (this.value === 'extensao-ead') {
			$('#cursos .banner .titulo .text').html('Extensão');
			$('#cursos .banner .titulo .big').html('EAD');
			$('.cursos .extensão.ead').fadeIn(400);
			$(this).addClass('checked')
		}
	});

	// Get anchor from URL
	let anchor = window.top.location.hash.substring(1);

	if (anchor) {
		$inputsType.each(function () {
			if (this.value === anchor) {
				console.log(this.value)
				$(this).attr('checked', 'checked');
			}
		});
	} else {
		anchor = 'graduacao-presencial';
	}

	$('input:radio[value="' + anchor + '"]').trigger("change");
}

Polos = {};
Polos.buscaEstadoCidade = function () {
	$('#filtro_estado').change(function () {
		$("#filtro_cidade option.cidades").hide();
		$("#filtro_cidade option[data-estado=" + $(this).val() + "]").show();
	});
	$('#filtro_cidade').change(function () {
		window.location.replace("?cidade=" + $(this).val());
	});
}

Noticias = {};
Noticias.buscaCategoria = function () {
	$('#filtro-categoria').change(function () {
		window.location.replace("?categoria=" + $(this).val());
	});
}

let Hotsite = {};
Hotsite.Dropdown = () => {
	$('#lab-select').on('change', function () {
		if ($(this).val() !== '') {
			window.location.href = $(this).val();
		}
	});
}

Site = {};
Site.Cookies = function () {
	lgpd = readCookie('lgpd');
	if (!lgpd) {
		$('#bannerCookies').css('display', 'flex');
	}
	$('#bannerCookies .btn-negar').click(function () {
		$('#bannerCookies').css('display', 'none');
	})
	$('#bannerCookies .btn-aceitar').click(function () {
		$('#bannerCookies').css('display', 'none');
		createCookie('lgpd', 'accepted', 365);
	})
}

function createCookie(name, value, days) {
	var expires;

	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toGMTString();
	} else {
		expires = "";
	}
	document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
	var nameEQ = encodeURIComponent(name) + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ')
			c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0)
			return decodeURIComponent(c.substring(nameEQ.length, c.length));
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name, "", -1);
}